import React, { useState, useEffect } from 'react';
import './prompt.scss'


export default function Prompt( props ) {
    let [display,setDisplay] = useState(true);

    useEffect(() => {
        if( !props.isStartup ) {
            // setTimeout(()=>{
            //     setDisplay(false)
            // },10000)
        }
        return () => {
        }
    },[ props.isStartup ]);

    return (
        <section className={`prompt ${display ? '' : 'hide'}`}>
            <div className="prompt-box">
                <img src="/assets/click-point.png" alt=""/>
                <div className="box-text">
                    <span>请等待保险箱打开</span><br/>
                    <span>然后触发书本操作</span>
                </div>
            </div>
        </section>
    )
}