import './webrtc.polyfill';
import React, { Suspense, useEffect, useState } from 'react';
import create from 'zustand';
import * as THREE from 'three';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { Environment, Stage, OrbitControls } from '@react-three/drei';
//import { Loading } from './loading';
import { GifLoading } from './loading/gif.js';
import { loadScript } from './utils';
import Box from './components/Box';
import SwitchCamera from './components/SwithCamera';
import './index.scss';

import Prompt from "./components/prompt";

const useStore = create((set) => ({
	init: false,
	camerainit: () => set((state) => ({ init: true }))
}))

let timeCount = 0;
let timeObj = null;

function gotDevices( mediaDevices ) {
	if(!(Array.isArray(mediaDevices) && mediaDevices.length)) return;
	return mediaDevices.filter(( mediaDevice, index ) => {
		return mediaDevice.kind === 'videoinput';
	});
}

function startupAR ( renderer, camera, scene ) {

	window.THREE = THREE;	

	return new Promise(( resolve, reject) => {

		navigator.mediaDevices.enumerateDevices().then( _devices => {

			let devices = gotDevices( _devices );

			loadScript('/js/ar.min.js').then(() => {

				const THREEx = window.THREEx;
	
				const w = window.innerWidth;
				const h = window.innerHeight;
				const last = devices[ devices.length - 1 ];

				var arToolkitSource = new THREEx.ArToolkitSource({
					sourceType : 'webcam',
					displayWidth: w,
					displayHeight: h,
					sourceWidth: w,
					sourceHeight: h,
					deviceId: last ? last.deviceId : null
				});
		
				// resize
				arToolkitSource.init(function onReady(){
					setTimeout(function(){
						onResize( renderer );
						resolve();
					},2000);
	
					timeObj = setInterval(() => {
						if(timeCount>=100){
							clearInterval(timeObj);
							return;
						}
						onResize( renderer );
						timeCount++;
					},50)
				}, e => {
					//console.log(e);
				});
	
				var arToolkitContext = new THREEx.ArToolkitContext({
					cameraParametersUrl: './assets/camera_para.dat',
					detectionMode: 'mono'
				});
	
				arToolkitContext.init(function onCompleted(){
				});
		
				createMarker('./assets/marker.patt');
		
				function createMarker ( marker ){
					// init controls for camera
					new THREEx.ArMarkerControls( arToolkitContext, camera, {
						type : 'pattern',
						patternUrl: marker,
						changeMatrixMode: 'cameraTransformMatrix',
						smooth: true
					});
					scene.visible = false;
				}
	
				window.arToolkitSource = arToolkitSource;
				window.arToolkitContext = arToolkitContext;
			});
		});
	});
}

function Model ( props ){

	let { gl, camera, scene } = useThree();

	useFrame(() => {
		let arToolkitSource = window.arToolkitSource;
		let arToolkitContext = window.arToolkitContext;
		if( arToolkitSource && arToolkitSource.ready === false ) return
		if( arToolkitContext ) {
			arToolkitContext.update( arToolkitSource.domElement );
			scene.visible = camera.visible;
		}
	});

	const increasePopulation = useStore((state) => state.camerainit);

	useEffect(() => {
		onResize( gl );
		scene.visible = false;
		startupAR( gl, camera, scene ).then(() => {
			// camerainit
			increasePopulation();
		});
		return () => {
		}
	}, []);

	return <Box {...props} />;
}

function onResize (){
	let arToolkitSource = window.arToolkitSource;
	let arToolkitContext = window.arToolkitContext;
	if( arToolkitSource && arToolkitSource.ready ) {
		arToolkitSource.onResizeElement();
		if( arToolkitContext.arController !== null ){
			arToolkitSource.copyElementSizeTo(arToolkitContext.arController.canvas);
		}
	}
}

function App() {
	const [isStartup, setStartup] = useState(true);
	const cameraInit = useStore((state) => state.init);
	const isInit = () =>{
		if( cameraInit ) {
			setStartup( false );
		}
	}

	return (
		<div className="app">
			<Canvas mode="concurrent" frameloop="always" dpr={[1,2]}>
				<Suspense
					fallback={<GifLoading />}>
					<Stage environment={null}
						adjustCamera={false}
						contactShadow={false}>
						<Model />
					</Stage>
					<Environment files="/hdr/Glazed_Patio_by_Restaurant.hdr" />
					<OrbitControls />
				</Suspense>
			</Canvas>
			{/* { cameraInit && <SwitchCamera onResize={onResize} /> } */}
			{ isStartup &&
			<div className="startup">
				<div className='startBtn'>
					<span onClick={isInit}>{cameraInit ? '点击开启AR识别' : '正在初始化相机..'}</span>
				</div>
			</div>
			}
			<div className={isStartup ? 'hide' : ''}>
				<Prompt isStartup={isStartup} />
			</div>
		</div>
	);
}

export default App;
