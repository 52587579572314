import React, { useRef, useState,useEffect } from 'react';
import * as THREE from 'three';
import { useAnimations, Text, useTexture } from '@react-three/drei';
import wx from 'weixin-js-sdk';
import { useGLTFLoader } from '../loaders';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import { useSpring, animated } from '@react-spring/three';
import { click } from '../audio';
import move from '../move';

let url = '/models/lock/file.gltf?t=' + (new Date().getTime());

export default function Model( props ) {

    const group = useRef();
    const { nodes, materials, animations } = useGLTFLoader(url);
    const { actions, mixer } = useAnimations(animations, group);
    const [ passwordArray, setPasswordArray ] = useState([]);
    const [ isPasswordCorrect, setPasswordCorrect ] = useState( false );
    const [ errorStatus, setErrorStatus ] = useState( false );
    const [ isInput, setInputStatus ] = useState( false ); 
    const password = useRef();

    let successTexture = useTexture("./assets/password_success.jpg");
    successTexture.flipY = false;
    successTexture.needsUpdate = true;

    let errorTexture = useTexture("./assets/password_error.jpg");
    errorTexture.flipY = false;
    errorTexture.needsUpdate = true;

    let [modelScale,setModelScale] = useState(1);

    let blackColor = new THREE.Color( 0x000000 );
    let whiteColor = new THREE.Color( 0xffffff );

    let rightScreenMaterial = nodes.polySurface235.material.clone();

    const [ visibleBook, setVisibleBook ] = useState( true );

    const { opacity } = useSpring({
        opacity: isPasswordCorrect ? 0.1 : 1,
        config: {
            duration: 2000
        },
    });

    useEffect(()=>{
        mixer.addEventListener( 'finished',function(e){
            setVisibleBook(false);
        });
        move(setModelScale,0.05);
        return ()=>{
        }
    },[]);

    function onTouchDown ( e ){
        e.stopPropagation();
        let obj = e.intersections[0].object;
        obj.position.z = 20;
        click.read().play();
    }

    function onTouchUp ( e ){
        e.stopPropagation();
        let obj = e.intersections[0].object;
        obj.position.z = 0;
    }

    function passwordHandle ( num ) {

        if( isPasswordCorrect ) return; //密码正确后不允许继续操作

        setErrorStatus( false );

        // 4位密码
        if( passwordArray.length >= 4 ) {
            return;
        }

        let n = clone(passwordArray).concat( num );
        setPasswordArray( n );

        if( n.length ) {
            setInputStatus( true ); //输入状态
        }
    }

    function passwordDel (){

        if( isPasswordCorrect ) return; //密码正确后不允许继续操作

        setErrorStatus( false );

        let n = clone(passwordArray);
        n.pop();
        setPasswordArray( n );

        if( n.length === 0 ) {
            setInputStatus( false ); 
        }
    }

    function passwordSubmit (){

        if( isPasswordCorrect ) return; //密码正确后不允许继续操作

        if(isEqual( passwordArray, [ 1,9,3,5 ])) {
            setPasswordCorrect( true );
            setPasswordArray([]);
            setInputStatus( false );

            let act = actions['polySurface208_M_KeypadLock_0|Take 001|BaseLayer'];
            act.loop = THREE.LoopOnce;
            act.timeScale = 10;
            act.clampWhenFinished = true;
            act.reset().play();
        }
        else {
            setPasswordCorrect( false );
            setPasswordArray([]);   //清空输入框
            setErrorStatus( true );
            setInputStatus( false );
        }
    }

    function jump(e){
        e.stopPropagation();
        if(isPasswordCorrect && !visibleBook){
            try {
                wx.miniProgram.redirectTo({ url: `/pages/notebook/index?index=""` });
            }catch (e) {
            }
        }
        else{
        }
    }

    return (
        <group scale={[modelScale,modelScale,modelScale]} {...props} dispose={null}>
            {/* rotation={[0,-Math.PI/2,-Math.PI/2]} */}
            <group position={[0,-1.5,0]} ref={group} scale={[0.1,0.1,0.1]} rotation={[Math.PI/2,-Math.PI/2,0]}>
                <group name="polySurface208_M_KeypadLock_0" 
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.01, 0.01, 0.01]}>
                    <mesh geometry={nodes.anniu_01.geometry} material={nodes.anniu_01.material} 
                        onPointerDown={ e => {
                            passwordHandle( 1 );
                            onTouchDown( e );
                        }}
                        onPointerUp={(e) => {
                            onTouchUp( e );
                        }} />
                    <mesh geometry={nodes.anniu_010.geometry} material={nodes.anniu_010.material}
                        onPointerDown={e => { passwordDel(); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    <mesh geometry={nodes.anniu_011.geometry} material={nodes.anniu_011.material}
                        onPointerDown={e => { passwordHandle( 0 ); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    <mesh geometry={nodes.anniu_012.geometry} material={nodes.anniu_012.material} 
                        onPointerDown={e => { passwordSubmit(); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    <mesh geometry={nodes.anniu_02.geometry} material={nodes.anniu_02.material}
                        onPointerDown={e => { passwordHandle( 2 ); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    <mesh geometry={nodes.anniu_03.geometry} material={nodes.anniu_03.material}
                        onPointerDown={e => { passwordHandle( 3 ); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    <mesh geometry={nodes.anniu_04.geometry} material={nodes.anniu_04.material}
                        onPointerDown={e => { passwordHandle( 4 ); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    <mesh geometry={nodes.anniu_05.geometry} material={nodes.anniu_05.material}
                        onPointerDown={e => { passwordHandle( 5 ); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    <mesh geometry={nodes.anniu_06.geometry} material={nodes.anniu_06.material}
                        onPointerDown={e => { passwordHandle( 6 ); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    <mesh geometry={nodes.anniu_07.geometry} material={nodes.anniu_07.material}
                        onPointerDown={e => { passwordHandle( 7 ); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    <mesh geometry={nodes.anniu_08.geometry} material={nodes.anniu_08.material}
                        onPointerDown={e => { passwordHandle( 8 ); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    <mesh geometry={nodes.anniu_09.geometry} material={nodes.anniu_09.material}
                        onPointerDown={e => { passwordHandle( 9 ); onTouchDown( e ); }}
                        onPointerUp={(e) => { onTouchUp( e ); }} />
                    {/* 左侧大屏幕 */}
                    <mesh geometry={nodes.polySurface234.geometry} material={nodes.polySurface234.material}></mesh>
                    {/* 右侧小屏幕 */}
                    <animated.mesh geometry={nodes.polySurface235.geometry}
                        material={rightScreenMaterial}
                        material-transparent={isPasswordCorrect}
                        material-opacity={opacity}
                        material-emissive={(isInput) ? blackColor : whiteColor }
                        material-emissiveMap={
                            isPasswordCorrect ? successTexture : 
                                ( errorStatus ? errorTexture : 
                                    ( isInput ? null : nodes.polySurface236.material.emissiveMap)
                                )}>
                        <Text
                            ref={password}
                            color={'#fff'}
                            fontSize={300}
                            maxWidth={5}
                            lineHeight={1}
                            letterSpacing={0.02}
                            textAlign={'left'}
                            font="/assets/font.TTF"
                            rotation={[ Math.PI, 0, Math.PI/2 ]}
                            position={[-2150,-800,-700]}
                            >
                            {passwordArray.join("")}
                        </Text>
                    </animated.mesh>
                    {/* 盒盖 */}
                    <animated.mesh visible={visibleBook} geometry={nodes.polySurface236.geometry}
                        material={ nodes.polySurface236.material }
                        material-transparent={isPasswordCorrect}
                        material-opacity={opacity} />
                </group>
                <group position={[-16, 0, 0]} rotation={[0, -1.57, 0]} onClick={jump}>
                    <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Mesh_0001.geometry}
                    material={nodes.Mesh_0001.material}
                    />
                    <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Mesh_1001.geometry}
                    material={materials['Book_Pages.001']}
                    />
                    <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Mesh_2001.geometry}
                    material={materials['Book_Tapa.001']}
                    />
                    <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Mesh_4001.geometry}
                    material={nodes.Mesh_4001.material}
                    />
                    <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Mesh_3001.geometry}
                    material={nodes.Mesh_3001.material}
                    />
                </group>
                {/* 盒体 */}
                <mesh geometry={nodes.polySurface233.geometry} 
                    material={materials['Material #35']} 
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.01, 0.01, 0.01]} />
            </group>
            {/* <Book visibleBook={visibleBook} visible={isPasswordCorrect} /> */}
        </group>
    )
}

useGLTFLoader.preload();
