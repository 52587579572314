import { Html, useProgress  } from '@react-three/drei';
import './index.scss';

export function GifLoading() {
    const {progress} = useProgress();
    return <Html center>
        <Gif progress={progress.toFixed(2)} />
    </Html>
}

export function Gif ( props ){
    return (<div className="com-loading">    
                <img className="earth" src="/assets/ar.gif"></img>      
         </div>)
}