import * as THREE from 'three';
import { GLTFLoader } from './GLTFLoader';
import { DRACOLoader, KTX2Loader } from 'three-stdlib'
import { useLoader } from '@react-three/fiber';
export function useGLTFLoader( path ){
    return useLoader( GLTFLoader, path, extensions() );
}

useGLTFLoader.preload = ( path ) => {
    return useLoader.preload(GLTFLoader, path, extensions());
}   

useGLTFLoader.clear = ( path ) => {
    return useLoader.clear(GLTFLoader, path);
}   

function extensions() {
    return ( loader ) => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath( '//static.sanxiangti.com/statics/draco/1.4.1/' );
        loader.setDRACOLoader( dracoLoader );

        const ktx2Loader = new KTX2Loader();
        ktx2Loader.setTranscoderPath( '//static.sanxiangti.com/statics/basis/' );
        ktx2Loader.detectSupport(new THREE.WebGLRenderer());
        loader.setKTX2Loader( ktx2Loader );
    }
  }

